// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customer-notifications-bonuses_PAi9s{position:relative;display:grid;grid-template-columns:1fr 28px 1fr;padding:12px 12px 24px;background-color:var(--OpacityLayer2);border-radius:5px}.customer-notifications-bonuses_PAi9s.alone_k8w4g{grid-template-columns:1fr}.customer-notifications-bonuses_PAi9s.alone_k8w4g .right_lsaen{align-items:center}.customer-notifications-bonuses_PAi9s.bottom_Jr70B{padding:24px 12px 12px;margin-top:6px}.customer-notifications-bonuses_PAi9s.bottom_Jr70B>.customer-notifications-bonuses--icon_wwn30{align-self:flex-start}.customer-notifications-bonuses_PAi9s.bottom_Jr70B .customer-notifications-bonuses--text_tqRGN{text-align:right}.customer-notifications-bonuses_PAi9s.bottom_Jr70B .customer-notifications-bonuses--text-box_hCXq3.right_lsaen>.customer-notifications-bonuses--text_tqRGN{text-align:left}.customer-notifications-bonuses--text-box_hCXq3{display:flex;flex-direction:column;align-items:flex-end}.customer-notifications-bonuses--text-box_hCXq3 div:first-child{margin-bottom:4px}.customer-notifications-bonuses--text-box_hCXq3>.customer-notifications-bonuses--text_tqRGN{font-size:14px;font-weight:500;line-height:16px;letter-spacing:.25px;color:var(--TextPrimary)}.customer-notifications-bonuses--text-box_hCXq3>.customer-notifications-bonuses--value_sAgei{font-size:18px;font-weight:500;line-height:20px;letter-spacing:normal;color:var(--TextDefault)}.customer-notifications-bonuses--text-box_hCXq3.right_lsaen{align-items:flex-start}.customer-notifications-bonuses--icon_wwn30{align-self:flex-end;color:var(--BrandDefault)}.customer-notifications-bonuses--arrow_F5TrF{position:absolute;bottom:-20px;left:50%;display:flex;align-items:center;justify-content:center;width:32px;height:32px;background-color:var(--Layer2);border-radius:16px;transform:translateX(-50%)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customer-notifications-bonuses": `customer-notifications-bonuses_PAi9s`,
	"alone": `alone_k8w4g`,
	"right": `right_lsaen`,
	"bottom": `bottom_Jr70B`,
	"customer-notifications-bonuses--icon": `customer-notifications-bonuses--icon_wwn30`,
	"customer-notifications-bonuses--text": `customer-notifications-bonuses--text_tqRGN`,
	"customer-notifications-bonuses--text-box": `customer-notifications-bonuses--text-box_hCXq3`,
	"customer-notifications-bonuses--value": `customer-notifications-bonuses--value_sAgei`,
	"customer-notifications-bonuses--arrow": `customer-notifications-bonuses--arrow_F5TrF`
};
export default ___CSS_LOADER_EXPORT___;
