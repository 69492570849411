// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fast-track-modal__message_CO25L{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px}.fast-track-modal__image_E_S0I{max-width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fast-track-modal__message": `fast-track-modal__message_CO25L`,
	"fast-track-modal__image": `fast-track-modal__image_E_S0I`
};
export default ___CSS_LOADER_EXPORT___;
